import React from 'react';
import Layout from '../ui/layout/Layout';
import {Section} from '../ui/layout/Grid';
import RegisterForm from '../ui/domains/registration/RegisterForm';

const Registrieren = () => {
    return (
        <Layout>
            <Section theme="blue">
                <RegisterForm theme="blue" />
            </Section>
        </Layout>
    );
};

export default Registrieren;
