import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

import {submitForm} from '../../../../model/registration';
import {Column, Container, Row} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Form, {Input, Select, Checkbox} from '../../../components/Form';
import Divider from '../../../components/Divider';
import {color} from '../../../../styles/variables';
import Button from '../../../components/Button';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import formLang from '../../../../lang/forms.lang';
import registrationLang from '../../../../lang/pages/registrieren.lang';
import {LOAD_LIST_BY_ZIP} from '../../../../model/pharmacy/actions';
import {selectPendingRequests} from '../../../../model/backend-api';

import {
    usernameValidatorStrict,
    emailValidator,
    passwordValidator,
    passwordConfirmationValidator,
    firstNameValidator,
    lastNameValidator,
    salutationValidator,
    identificationNumberValidator,
    pharmacyValidator,
    termsOfUseAndPrivacyPolicyValidator,
    sharePointsValidator,
} from '../../../../utils/form-validation';

import {
    loadListByZip,
    selectSortedPharmacyListByName,
    clearAllPharmacy,
} from '../../../../model/pharmacy';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';
import InfoTooltip from '../../../components/Form/InfoTooltip';
import {TooltipIcon} from '../../../components/Form/InfoTooltip.styles';
import useToggle from '../../../../utils/toggle-props';

const validationSchema = yup.object().shape({
    salutation: salutationValidator,
    username: usernameValidatorStrict,
    email: emailValidator,
    firstName: firstNameValidator,
    lastName: lastNameValidator(true),
    password: passwordValidator(true),
    passwordConfirmation: passwordConfirmationValidator,
    identificationNumber: identificationNumberValidator,
    pharmacy: pharmacyValidator(),
    termsOfUseAndPrivacyPolicy: termsOfUseAndPrivacyPolicyValidator,
    sharePoints: sharePointsValidator,
});

const defaultValues = {
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    identificationNumber: '',
    referralCode: '',
    salutation: '',
    pharmacy: '',
    termsOfUseAndPrivacyPolicy: false,
    sharePoints: false,
    newsletter: false,
};

export const RegisterForm = ({
    onSubmit,
    pharmacyList,
    theme,
    isLoading,
    fetchPharmaciesByZip,
    clearPharmacy,
}) => {
    const {control, handleSubmit, errors} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
    const tranformDataForSubmit = (data) => {
        const tranformedData = {
            ...data,
            salutation: data.salutation.value,
            pharmacyId: data.pharmacy.value,
            termsOfUse: data.termsOfUseAndPrivacyPolicy,
            privacyPolicy: data.termsOfUseAndPrivacyPolicy,
        };
        onSubmit(tranformedData);
    };
    const pharmacySelectData = pharmacyList.map((pharmacy) => ({
        value: pharmacy.id,
        label: `${pharmacy.name}, ${pharmacy.zip}`,
        zip: pharmacy.zip,
    }));

    const filterOptions = (option, input) => {
        if (input.length < 3) {
            return null;
        }
        return option.data.zip.includes(input);
    };

    const [usernameTtIsOpen, usernameTtItoogleIsOpen] = useToggle();
    const [idTtIsOpen, idTtItoogleIsOpen] = useToggle();
    const [passwordTtIsOpen, passwordTtItoogleIsOpen] = useToggle();
    const [referralCodeTtIsOpen, referralCodeTtItoogleIsOpen] = useToggle();

    return (
        <Form theme={theme} onSubmit={handleSubmit(tranformDataForSubmit)}>
            <Container width="narrow">
                <Row>
                    <Column xs={12}>
                        <Headline type="h1" align="center">
                            {registrationLang.headline}
                        </Headline>
                        <Paragraph align="center">
                            {registrationLang.intro}
                        </Paragraph>
                    </Column>
                    <Column xs={12}>
                        <Divider dashed color={color.white} />
                    </Column>
                    <Column md={6}>
                        <Select
                            name={formLang.salutation.name}
                            label={formLang.salutation.label}
                            sectionTheme={theme}
                            options={formLang.salutation.options}
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column md={6}>
                        <InfoTooltip
                            text={formLang.username.tooltip.text}
                            open={usernameTtIsOpen}
                            setOpen={usernameTtItoogleIsOpen}>
                            <Input
                                name={formLang.username.name}
                                placeholder={formLang.username.placeholder}
                                label={formLang.username.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                            <TooltipIcon
                                onClick={usernameTtItoogleIsOpen}
                                name="info"
                                size="1.5rem"
                                color={
                                    usernameTtIsOpen
                                        ? color.petrol
                                        : color.darkBlue
                                }
                            />
                        </InfoTooltip>
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.firstName.name}
                            placeholder={formLang.firstName.placeholder}
                            label={formLang.firstName.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.lastName.name}
                            placeholder={formLang.lastName.placeholder}
                            label={formLang.lastName.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.email.name}
                            placeholder={formLang.email.placeholder}
                            label={formLang.email.label}
                            theme={theme}
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column md={6}>
                        <InfoTooltip
                            text={formLang.referralCode.tooltip.text}
                            open={referralCodeTtIsOpen}
                            setOpen={referralCodeTtItoogleIsOpen}>
                            <Input
                                name={formLang.referralCode.name}
                                placeholder={formLang.referralCode.placeholder}
                                label={formLang.referralCode.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                            />
                            <TooltipIcon
                                onClick={referralCodeTtItoogleIsOpen}
                                name="info"
                                size="1.5rem"
                                color={
                                    referralCodeTtIsOpen
                                        ? color.petrol
                                        : color.darkBlue
                                }
                            />
                        </InfoTooltip>
                    </Column>
                    <ResponsiveSwitch>
                        {(match) => {
                            if (match.md) {
                                return <Column md={6} />;
                            } else {
                                return null;
                            }
                        }}
                    </ResponsiveSwitch>
                    <Column md={12}>
                        <Select
                            name={formLang.pharmacy.name}
                            label={formLang.pharmacy.label1}
                            placeholder={formLang.zip.placeholder}
                            blurInputOnSelect={true}
                            sectionTheme={theme}
                            onMenuOpen={() => {
                                clearPharmacy();
                            }}
                            isNumber
                            maxLength={5}
                            noOptionsMessage={() =>
                                isLoading
                                    ? formLang.pharmacy.loadPharmacies
                                    : formLang.pharmacy.noPharmacies
                            }
                            options={pharmacySelectData}
                            filterOption={filterOptions}
                            clearValueOnFocus={true}
                            control={control}
                            errors={errors}
                            onInputChange={(input) => {
                                if (input.length === 3 || input.length === 5) {
                                    fetchPharmaciesByZip(input);
                                }
                            }}
                        />
                    </Column>
                    <Column xs={12}>
                        <InfoTooltip
                            text={formLang.idNumber.tooltip.text}
                            open={idTtIsOpen}
                            setOpen={idTtItoogleIsOpen}>
                            <Input
                                name={formLang.idNumber.name}
                                placeholder={formLang.idNumber.placeholder}
                                label={formLang.idNumber.label}
                                theme={theme}
                                control={control}
                                errors={errors}
                                type="number"
                            />
                            <TooltipIcon
                                onClick={idTtItoogleIsOpen}
                                name="info"
                                size="1.5rem"
                                color={
                                    idTtIsOpen ? color.petrol : color.darkBlue
                                }
                            />
                        </InfoTooltip>
                    </Column>
                    <Column md={6}>
                        <InfoTooltip
                            text={formLang.password.tooltip.text}
                            open={passwordTtIsOpen}
                            setOpen={passwordTtItoogleIsOpen}>
                            <Input
                                name={formLang.choosePassword.name}
                                placeholder={
                                    formLang.choosePassword.placeholder
                                }
                                label={formLang.choosePassword.label}
                                theme={theme}
                                type="password"
                                control={control}
                                errors={errors}
                            />
                            <TooltipIcon
                                onClick={passwordTtItoogleIsOpen}
                                name="info"
                                size="1.5rem"
                                color={
                                    passwordTtIsOpen
                                        ? color.petrol
                                        : color.darkBlue
                                }
                            />
                        </InfoTooltip>
                    </Column>
                    <Column md={6}>
                        <Input
                            name={formLang.passwordConfirm.name}
                            placeholder={formLang.passwordConfirm.placeholder}
                            label={formLang.passwordConfirm.label}
                            theme={theme}
                            control={control}
                            type="password"
                            errors={errors}
                        />
                    </Column>
                    <Column xs={12} className="ap-padding--top">
                        <Checkbox
                            name={formLang.termsOfUseAndPrivacyPolicy.name}
                            label={formLang.termsOfUseAndPrivacyPolicy.label}
                            control={control}
                            errors={errors}
                        />
                        <Checkbox
                            name={formLang.newsletter.name}
                            label={formLang.newsletter.label}
                            control={control}
                            errors={errors}
                        />
                        <Checkbox
                            name={formLang.sharePoints.name}
                            label={formLang.sharePoints.label}
                            control={control}
                            errors={errors}
                        />
                    </Column>
                    <Column xs={12}>
                        <Button
                            type="submit"
                            iconRight
                            label={registrationLang.submitButton}
                        />
                    </Column>
                </Row>
            </Container>
        </Form>
    );
};

RegisterForm.propTypes = {
    onSubmit: PropTypes.func,
    clearPharmacy: PropTypes.func,
    pharmacyList: PropTypes.array,
    theme: PropTypes.oneOf(sectionThemeNames),
    isLoading: PropTypes.bool,
    fetchPharmaciesByZip: PropTypes.func,
};

RegisterForm.defaultProps = {
    onSubmit: () => {},
    clearPharmacy: () => {},
    pharmacyList: [],
    theme: 'default',
    isLoading: false,
    fetchPharmaciesByZip: () => {},
};

const ConnectedRegisterForm = (props) => {
    if (process.env.STORYBOOK_ENV === 'true') {
        return <RegisterForm {...props} />;
    }
    const dispatch = useDispatch(); // eslint-disable-line
    const handleSubmit = (data) => {
        dispatch(submitForm(data));
    };
    const loadPharmaciesByZip = (zip) => {
        dispatch(loadListByZip(zip));
    };

    const clearPharmacy = () => {
        dispatch(clearAllPharmacy());
    };

    const sortedPharmacyList = useSelector(selectSortedPharmacyListByName); // eslint-disable-line
    const pendingRequests = useSelector(selectPendingRequests); // eslint-disable-line
    return (
        <RegisterForm
            {...props}
            onSubmit={handleSubmit}
            pharmacyList={sortedPharmacyList}
            clearPharmacy={clearPharmacy}
            isLoading={pendingRequests.some((e) => e.type === LOAD_LIST_BY_ZIP)}
            fetchPharmaciesByZip={loadPharmaciesByZip}
        />
    );
};

export default ConnectedRegisterForm;
