import styled, {css} from 'styled-components';

import {color} from '../../../styles/variables';
import {sm, lg} from '../../../styles/media';
import Paragraph from '../Paragraph';
import Icon from '../Icon';

export const Wrapper = styled.div`
    position: relative;
`;

export const TooltipIcon = styled(Icon)`
    position: absolute;
    display: inline-block;
    right: 1rem;
    top: 40px;
    cursor: pointer;

    ${sm`
        top: 42px;
    `}
`;

const tooltipPosition = css`
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 2;

    ${lg`
        right: calc(-150px + 1rem + 0.75rem);
    `}
`;

export const Container = styled.div`
    position: relative;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    background-color: ${color.petrol};
    width: 100%;
    max-width: 300px;
    padding: 1rem;
    ${(props) => props.isOpen && tooltipPosition};

    &:before {
        content: '';
        width: 0;
        height: 0;
        border-left: 1.8rem solid transparent;
        border-right: 1.8rem solid transparent;
        border-bottom: 1.3rem solid ${color.petrol};
        position: absolute;
        top: -0.5rem;
        right: 0;

        ${lg`
            right: unset;
            transform: translateX(-50%);
            left: 50%;
        `}
    }
`;

export const TooltipText = styled(Paragraph)`
    color: ${color.white};
`;
