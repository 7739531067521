/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Wrapper, Container, TooltipText} from './InfoTooltip.styles';

/* The children prop is supposed to contain the component that is clicked on
to display the tooltip;
the default children are an input component with an info icon on its right,
see InfoTooltip.stories */
const InfoTooltip = ({head, text, open, setOpen, children}) => {
    const toolTipRef = useRef();

    const handleClickOutside = (e) => {
        if (toolTipRef.current.contains(e.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        open
            ? document.addEventListener('mousedown', handleClickOutside)
            : document.removeEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, [open]);

    return (
        <Wrapper>
            <Container ref={toolTipRef} isOpen={open}>
                {head && (
                    <TooltipText>
                        <>
                            <strong>{head}</strong>
                        </>
                    </TooltipText>
                )}
                {text && <TooltipText margin="0">{text}</TooltipText>}
            </Container>
            {children}
        </Wrapper>
    );
};

InfoTooltip.propTypes = {
    head: PropTypes.string,
    text: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    children: PropTypes.any,
};

InfoTooltip.defaultProps = {
    head: '',
    text: '',
};

export default InfoTooltip;
