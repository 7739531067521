import React from 'react';

export default {
    headline: (
        <>
            <i>
                Jetzt <strong>registrieren!</strong>
            </i>
        </>
    ),
    intro: <>Lege jetzt Dein Profil an und nutze alle Vorteile von Apotique.</>,
    submitButton: 'Fertigstellen',
    userRegistrationConfirmed: {
        headline: <b>Vielen Dank für die Prüfung!</b>,
        paragraph: <>Du hast den Bewerber der Apotheke hinzugefügt.</>,
    },
    userRegistrationDeclined: {
        headline: <b>Vielen Dank für die Prüfung!</b>,
        paragraph: <>Du hast den Bewerber abgelehnt. </>,
    },
};
